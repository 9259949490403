import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import EventBar from "../../components/EventBar/EventBar";
import useShipments from "../../hooks/useShipments";
import ShipmentContentData from "../../components/ShipmentContentData/ShipmentContentData";

function Shipment() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let shipmentCode = params.get("shipmentCode");

  const {
    singleShipmentData,
    fetchSingleShipment,
    telemetryInfo,
    alertsListInfo,
    chartsInfo,
    eventsInfo,
  } = useShipments();

  const [shipmentParameters, setShipmentParameters] = useState({
    current: 0,
    status: "loading",
    statusCode: 0,
    file: "",
    standalone: false,
    redirect: false,
    message: "Cargando...",
    shipmentCode: shipmentCode,
    telemetryTypeCode: null,
    imei: null,
    macMando: null,
  });

  useEffect(() => {
    const getShipment = async () => {
      const fetchData = await fetchSingleShipment(shipmentCode);
    };

    getShipment();
  }, []);

  useEffect(() => {
    if (singleShipmentData.statusCode === 0) {
      setShipmentParameters((prevState) => ({
        ...prevState,
        message: "Cargando",
        status: "loading",
        statusCode: 0,
      }));
      return false;
    }

    if (singleShipmentData.statusCode !== 200) {
      return setShipmentParameters((prevState) => ({
        ...prevState,
        message: singleShipmentData.message,
        statusCode: singleShipmentData.statusCode,
        status: "loaded",
      }));
    }

    if (singleShipmentData.statusCode === 200) {
      setShipmentParameters((prevState) => ({
        ...prevState,
        file: singleShipmentData.file,
        standalone: singleShipmentData.standAlone,
        statusCode: singleShipmentData.statusCode,
        status: "loaded",
        telemetryTypeCode: singleShipmentData.telemetryTypeCode,
        imei: singleShipmentData.imei,
        macMando: singleShipmentData.macMando,
      }));
    }
  }, [singleShipmentData]);

  return (
    <div className="Envio">
      <EventBar
        shipmentCode={shipmentParameters.shipmentCode}
        eventsInfo={eventsInfo}
      />
      <ShipmentContentData
        shipmentParameters={shipmentParameters}
        telemetryInfo={telemetryInfo}
        alertsListInfo={alertsListInfo}
        chartsInfo={chartsInfo}
      />
    </div>
  );
}

export default withTranslation()(Shipment);
