import React from "react";
import { withTranslation } from "react-i18next";
import {
  Row,
  Col,
  Button,
  Collapse,
  Icon,
  DatePicker,
  Select,
  Alert,
} from "antd";
import moment from "moment";
import "moment/locale/es";

const { RangePicker } = DatePicker;
const { Option } = Select;

function AlarmsSearch({
  onSearch,
  onDateChange,
  onExtraFilterChange,
  filterOptions,
  searchParameters,
}) {
  const { Panel } = Collapse;

  const [dateError, setDateError] = React.useState(null);

  const predefinedRanges = {
    Hoy: [moment().startOf("day"), moment().endOf("day")],
    "Últimas 3h": [moment().subtract(3, "hours"), moment()],
    "Últimas 12h": [moment().subtract(12, "hours"), moment()],
    "Últimas 24h": [moment().subtract(24, "hours"), moment()],
    "Últimos 7 días": [moment().subtract(7, "days"), moment()],
  };

  const handleKeyDown = (e) => {
    e.preventDefault();
  };

  const handleSearchAlarms = (e) => {
    e.preventDefault();
    onSearch();
  };

  const handleDateChange = (dates) => {
    if (dates && dates[0] && dates[1]) {
      const diff = dates[1].diff(dates[0], "months", true);
      if (diff > 1) {
        setDateError("El rango máximo permitido es de un mes.");
        onDateChange([null, null]);
        return;
      }
    }
    setDateError(null);
    onDateChange(dates);
  };

  const isDisabled =
    !searchParameters.onDateRangePicked &&
    !searchParameters.startDateAlarm &&
    !searchParameters.endDateAlarm;

  return (
    <div className="AlarmsSearch">
      {dateError && (
        <Alert
          className="alert-error"
          message={dateError}
          type="error"
          showIcon
        />
      )}
      <h2>Buscador de Alarmas</h2>
      <Row>
        <Collapse
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <Icon type="caret-right" rotate={isActive ? 90 : 0} />
          )}
        >
          <Panel
            header="Buscador"
            key="1"
            extra={<Icon type="minus-circle" />}
            className="Busca"
          >
            <Row>
              <form onSubmit={handleSearchAlarms}>
                <Col lg={8}>
                  <label>Rango de fechas</label>
                  <br />
                  <RangePicker
                    showTime={{ format: "HH:mm" }}
                    format="DD-MM-YYYY  HH:mm"
                    placeholder={["Desde", "Hasta"]}
                    onChange={(dates) => handleDateChange(dates)}
                    ranges={predefinedRanges}
                    style={{ width: "90%" }}
                    value={
                      searchParameters.startDateAlarm &&
                      searchParameters.endDateAlarm
                        ? [
                            searchParameters.startDateAlarm,
                            searchParameters.endDateAlarm,
                          ]
                        : null
                    }
                  />
                </Col>
                <Col lg={14}>
                  <label>Tipos de alarmas</label>
                  <br />
                  <Select
                    mode="multiple"
                    style={{ width: "90%" }}
                    onChange={(labels) => onExtraFilterChange(labels)}
                    placeholder="Filtre los tipos de alarmas para una búsqueda avanzada"
                    onInputKeyDown={handleKeyDown}
                  >
                    {filterOptions.map((item) => (
                      <Option key={item.value} value={item.label}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col lg={2}>
                  <br />
                  <Button
                    type="submit"
                    htmlType="submit"
                    disabled={isDisabled}
                    className={isDisabled ? "disabled" : "search-btn"}
                  >
                    Buscar
                  </Button>
                </Col>
              </form>
            </Row>
          </Panel>
        </Collapse>
      </Row>
    </div>
  );
}

export default withTranslation()(AlarmsSearch);
