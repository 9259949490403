import React from "react";
import { withTranslation } from "react-i18next";
import Alerts from "../Alerts/Alerts";
import AlertsList from "../AlertsList/AlertsList";
import ShipmentCharts from "../ShipmentCharts/ShipmentCharts";

function OnlineDataLoggerShipmentData({
  shipmentParameters,
  telemetryInfo,
  alertsListInfo,
  chartsInfo,
}) {
  const { imei, macMando } = shipmentParameters;

  let macDatalogger;
  macMando && (macDatalogger = `(${macMando})`);

  return (
    <div>
      <h2>Tipo de datalogger: On-Line {macDatalogger}</h2>
      <h2>IMEI de caja: {imei}</h2>
      {chartsInfo.telemetryInfo.length === 0 ? (
        <h4 style={{ height: "20vw", textAlign: "center", paddingTop: "8vw" }}>
          El envío no dispone por el momento de registros de telemetría. Por
          favor, inténtelo de nuevo más tarde.
        </h4>
      ) : (
        <>
          <Alerts
            shipmentCode={shipmentParameters.shipmentCode}
            telemetryInfo={telemetryInfo}
            hasTelemetry
            isStandalone={shipmentParameters.standalone}
          />
          <AlertsList
            shipmentCode={shipmentParameters.shipmentCode}
            alertsListInfo={alertsListInfo}
          />
          <ShipmentCharts
            shipmentCode={shipmentParameters.shipmentCode}
            chartsInfo={chartsInfo}
            isStandalone={shipmentParameters.standalone}
          />
        </>
      )}
    </div>
  );
}

export default withTranslation()(OnlineDataLoggerShipmentData);
